import React, { useContext, useEffect, useState } from "react"
import { navigate } from "gatsby"
import {
  faHome,
  faBuilding,
  faHospital,
} from "@fortawesome/free-solid-svg-icons"

import Section from "elements/Section"
import ActionButtons from "../Elements/ActionButtons"
import EditDetailsButton from "../Summary/EditDetailsButton"
import ConsentAndAuthorization from "../Summary/ConsentAndAuthorization"
import PatientInformationSummary from "./PatientEnrollmentSummary/PatientInformationSummary"
import FinancialInformationSummary from "./PatientEnrollmentSummary/FinancialInformationSummary"
import PrescriptionInformationSummary from "./PatientEnrollmentSummary/PrescriptionInformationSummary"

import {
  shouldPatientButtonBeDisabled,
  sendEnrollmentForm,
} from "./services/enrollment"
import { AppContext } from "../../context/AppContext"
import termsAndConditionsData from "./utils/patientConsentAndAuthorization.json"
import patientEnrollmentTemplate from "./utils/templates/patientEnrollmentEmailTemplate"
import { patientEnrollmentTicketBody } from "./utils/templates/patientEnrollmentZendeskTemplate"
import { patientTextMessageTemplate } from "./utils/templates/enrollmentTextMessageTemplate"

const PatientEnrollmentSummary = () => {
  const [isLoading, setLoading] = useState(false)
  const { state, dispatch } = useContext(AppContext)
  const { patientEnrollment } = state
  const addressIcon =
    patientEnrollment?.patientAddress?.addressType === "Home"
      ? faHome
      : patientEnrollment?.patientAddress?.addressType === "Office"
      ? faBuilding
      : faHospital

  useEffect(() => {
    dispatch({ type: "GET_CONTEXT_FROM_SESSION" })
  }, [])

  const handleSuccessSubmit = async () => {
    setLoading(false)
    await dispatch({ type: "REMOVE_CONTEXT_FROM_SESSION" })
    await dispatch({ type: "RESET_DETAILS" })
    navigate("/complete", {
      state: {
        type: "patient",
      },
    })
  }

  const handleErrorSubmit = () => {
    setLoading(false)
    navigate("/complete", {
      state: {
        type: "patient",
      },
    })
  }

  const handleSubmit = async () => {
    setLoading(true)
    await sendEnrollmentForm({
      enrollmentData: patientEnrollment,
      emailTemplate: patientEnrollmentTemplate,
      ticketTemplate: patientEnrollmentTicketBody,
      type: "patient",
      callback: handleSuccessSubmit,
      errorCallback: handleErrorSubmit,
      textMessageTemplate: patientTextMessageTemplate,
    })
  }

  return (
    <div>
      <Section
        title="Patient Information"
        addOns={{
          right: (
            <EditDetailsButton route="/enroll-patient#patient-information" />
          ),
        }}
      >
        <PatientInformationSummary
          patientEnrollment={patientEnrollment}
          addressIcon={addressIcon}
        />
      </Section>
      <Section
        title="Prescription Information"
        addOns={{
          right: (
            <EditDetailsButton route="/enroll-patient#prescription-information" />
          ),
        }}
      >
        <PrescriptionInformationSummary patientEnrollment={patientEnrollment} />
      </Section>

      <Section
        title="Financial Information"
        addOns={{
          right: (
            <EditDetailsButton route="/enroll-patient#financial-information" />
          ),
        }}
      >
        <FinancialInformationSummary patientEnrollment={patientEnrollment} />
      </Section>

      <ConsentAndAuthorization
        handleOnSubmit={handleSubmit}
        termsAndConditionsData={termsAndConditionsData}
        flow="patientEnrollment"
      >
        <ActionButtons
          hasCaptcha={true}
          back={{ label: "Back", link: "/enroll-patient" }}
          submit={{
            loading: isLoading,
            label: "Submit",
            disabled: shouldPatientButtonBeDisabled({
              formData: patientEnrollment,
            }),
          }}
        />
      </ConsentAndAuthorization>
    </div>
  )
}

export default PatientEnrollmentSummary
